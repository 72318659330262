import React, { FC, useEffect, useState, useMemo } from "react";
import ReactDOM from "react-dom";
import styles from "./styles.module.scss";

type HistoryItem =
  | Record<"input" | "output", string>
  | Record<"system", string>;

const HistoryViewer: FC = () => {
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [window.location.search]
  );
  const [chatHistory, setChatHistory] = useState<HistoryItem[]>([]);

  useEffect(() => {
    (async () => {
      if (!searchParams.get("file")) {
        return;
      }
      const response = await fetch(
        `/api/chat?file=${searchParams.get("file")}`
      );
      const data = await response.json();
      setChatHistory(data);
    })();
  }, [searchParams.get("file")]);

  return (
    <div className={styles.messageContainer}>
      {chatHistory.map((item) =>
        "system" in item ? (
          <div className={styles.messageSystem}>
            System: {item.system.trim()}
          </div>
        ) : (
          <>
            <div className={styles.messageUser}>
              <div className={styles.bubbleUser}>{item.input.trim()}</div>
            </div>
            <div className={styles.messageAssistant}>
              <div className={styles.bubbleAssistant}>{item.output.trim()}</div>
            </div>
          </>
        )
      )}
    </div>
  );
};

ReactDOM.render(<HistoryViewer />, document.getElementById("app"));
